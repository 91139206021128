.contactos-content{
    text-align: center;
}
.contactos-content h2,p{
    color: white;
    padding-bottom: 2px;
}
iframe{
    width: 90%;
    height: 300px;
    margin: auto;
 }   
@media screen and (max-width: 780px){
 iframe{
    width: 90%;
    height: 200px;    
 }   
}