
@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');

.navbar {
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    border: 1px solid white;
    border-top: none;
    border-right: none;
    border-left: none;
   
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
  }
  
  .navbar-logo {
    font-size: 30px;
    font-weight: bold;
    color: rgb(249, 249, 249);
    font-family: 'Antonio', sans-serif;

  }
  
  .navbar-toggle {
    cursor: pointer;
  }
  
  .navbar-toggle-icon {
    display: block;
    width: 30px;
    height: 10px;
    background-color: #ab2f2f;
    transition: transform 0.3s;
  }

  .language-selector img{
    width: 30px;
    height: 30px;
    border-radius: 50%;;
    margin: 0 4px;
  }
  .language-button{
  background-color: transparent;
  border: none;
cursor: pointer;
  }

  .language-button img:hover{
    scale:120%;
  }

  .language-selector{
    padding-top: 10px;
    position: relative;
  }
  
  .navbar-toggle.active .navbar-toggle-icon {
    transform: rotate(45deg);
  }
  
  .navbar-menu {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    width: 50%;

  }
  .navbar-link-button {
    background: red;
    color: rgb(254, 254, 254);
    text-decoration: none;
    font-weight: bold;
    font-size: 26px;
    cursor: pointer;
    padding: 0;
    font: inherit;
    font-family: 'Times New Roman', Times, serif;
    height: 40px;
    border-radius:20px;
    width: 120%;
  }
  
  .navbar-link-button:hover,
  .navbar-link-button:focus {
    text-decoration: none;
    color: rgb(226, 93, 39);
  }
  .navbar-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: absolute;
    background-color: rgba(20, 29, 35, 0.951);
    width: 88%;
    height: 55vh;
    z-index:99;
  }
  
  .navbar-item {
    margin: 10px 0;
    padding-top: 10px;
  }

  .navbar-link {
    color: rgb(254, 254, 254);
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
  }
  .navbar-link:hover{
    color: rgb(226, 93, 39);

  }
  @media screen and (min-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
    }
    .modal-content {
      padding: 10px;
      border-radius: 5px;
      position: relative;
      width: 100%;
  
    }
    .navbar-toggle {
      display: none;
    }
  
    .navbar-menu {
      display: flex;
      align-items: center;
    }
  
    .navbar-item {
      margin: 0 10px;
    }
 

  }
  