@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap');
.homepage {
    color: rgb(255, 255, 255);
    font-family: Arial, sans-serif;
    position:relative;
    height: 100vh;
    background-image: url('../../assets/horno.jpeg' ) ;
    background-repeat: no-repeat;
    background-size: cover;
  
    background-attachment: scroll;
  }
  
  .header {
    text-align: center;
    padding: 40px;
    position: relative;
    top: 80px;
    gap: 20px;
  }
  .cssanimation {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.cssanimation span {
    display: inline-block;
    opacity: 0;
}

.fadeInTop {
    animation-name: fadeInTopAnimation;
}

.fadeInBottom {
    animation-name: fadeInBottomAnimation;
}

@keyframes fadeInTopAnimation {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInBottomAnimation {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


  h1 {
    color: rgb(255, 255, 255);
    font-size: 48px;
    margin-bottom:50px;
    font-family: 'Cabin Sketch', sans-serif; font-size: 4vw;  }
  
  p {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: rgb(196, 17, 0);
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-decoration: none;
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: rgb(246, 57, 5);
  }
  
  .homepage-pizza1{
    position: absolute;
    top:240px;
    left:1100px;
    z-index: -1;    

  }



  .homepage-pizza2{
    position: absolute;
    top:50px;
    left:600px;
    width: 90%;
    z-index: -1;
    opacity: 0.2;
    width: 290px;
  }
  @media screen and (max-width: 1180px){
    .homepage {

      height: 80vh;
      overflow: hidden;
      
    }
    .homepage .header{
      display: flex;
      flex-direction: column;
      gap: 20px;

    }
    .homepage-pizza1{
      top: 10px;
      left:0;
      position: relative;
    margin: auto;
    
    }
    .homepage-pizza2{
      top:-40px;
      left: 0;
 
    }
    h1 {
      font-size: 8vw; 
    }

    p{
      font-size: 16px;
    }

  }


  @media screen and (max-width: 350px){
    .homepage {
  background-position: -140px;
    }
  }