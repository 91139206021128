.footer {
  border-style: solid;
  color: #fff;
  padding: 20px;
  align-content: center;
  text-align: center;
  /* position: relative; */
  border-width: 2px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  }
  .trip_advisor img{
width:300px;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .social-links a {
    color: #fff;
    font-size: 24px;
    margin: 0 10px;
  }
  
  .contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-info p {
    top: 14px;
    position: relative;
    margin-left: 5px;
    font-size: 12px;

  }
  
  .copy-right {
    font-size: 14px;
  }
  
  @media screen and (max-width: 780px){
    
  }