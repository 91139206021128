.about-us-container {

    height: 100vh;
  }
  .about-us-content{
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.92);
    top:100px;
    position: relative;
  }
  .about-us-title {
    color: #c41100;
    font-size: 24px;
    margin-bottom: 16px;
    font-size: 30px;
    text-align: center;
  }
  
  .about-us-description {
    color: #ffe2ad;
    font-size: 22px;
    margin-bottom: 12px;
  }

  .about-us-container img{
    opacity: 0.3;
    border-radius: 50%;
    position: absolute;
    right:-400px;
    top:100px;
  }
  @media screen and (max-width: 780px){
    .about-us-container{
        height: 150vh;
    }
    .about-us-content p {
   
        font-size: 18px;

      }

      .about-us-container img{
    left: 10px;  
    }
    .about-us-content{overflow: hidden;
    }

  }
  